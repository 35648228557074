import React from "react";

function PolicyPageContentARB() {
  return (
    <div dir="rtl" className=" text-right">
      <h1 className="text-2xl font-bold mb-6">الخصوصية</h1>
      <p className="text-sm mb-6">آخر تحديث 23/4/2024</p>
      <p className="mb-4">
        هذا الإشعار الخاص بالخصوصية لـ KalimatKeywords ("الشركة"، "نحن"، "نحن"،
        "خاصتنا")، يصف كيف ولماذا قد نجمع، نخزن، نستخدم، و/أو نشارك ("نعالج")
        معلوماتك عند استخدامك لخدماتنا ("الخدمات")، مثل عند:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          زيارة موقعنا على kalimatkeywords.com، أو أي موقع ويب لنا يرتبط بهذا
          الإشعار الخاص بالخصوصية.
        </li>
        <li>
          التفاعل معنا بطرق أخرى متعلقة، بما في ذلك أي مبيعات، تسويق، أو أحداث.
        </li>
      </ul>
      <p className="mb-16">
        لديك أسئلة أو مخاوف؟ قراءة هذا الإشعار الخاص بالخصوصية ستساعدك على فهم
        حقوقك وخياراتك الخاصة بالخصوصية. إذا كنت لا توافق على سياساتنا
        وممارساتنا، يرجى عدم استخدام خدماتنا. إذا كان لديك أي أسئلة أو مخاوف
        أخرى، يرجى الاتصال بنا عبر البريد الإلكتروني{" "}
        <a
          href="mailto:info@kalimatkeywords.com"
          className="text-custom-dark-blue font-bold hover:text-blue-800"
        >
          info@kalimatkeywords.com
        </a>
        .
      </p>

      {/* Second Section */}
      <h2 className="text-2xl font-semibold mb-2">
        1. ما هي المعلومات التي نجمعها؟
      </h2>
      <p className="mb-4">
        <strong>المعلومات الشخصية التي تكشفها لنا</strong>
        <br />
        باختصار: نقوم بجمع المعلومات الشخصية التي تقدمها لنا.
      </p>
      <p className="mb-4">
        نقوم بجمع المعلومات الشخصية التي تقدمها لنا طوعًا عندما تعرب عن اهتمامك
        بالحصول على معلومات عنا أو عن منتجاتنا وخدماتنا، أو عندما تشارك في
        الأنشطة المتعلقة بالخدمات، أو عندما تتصل بنا. المعلومات الشخصية التي
        نجمعها تعتمد على سياق تفاعلاتك معنا ومع الخدمات، والاختيارات التي تقوم
        بها، والمنتجات والميزات التي تستخدمها. تتضمن المعلومات الشخصية التي
        نجمعها ما يلي:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>الأسماء</li>
        <li>عناوين البريد الإلكتروني</li>
        <li>العناوين البريدية</li>
      </ul>
      <p className="mb-4">
        <strong>معلومات حساسة.</strong> نحن لا نقوم بمعالجة المعلومات الحساسة.
      </p>
      <p className="mb-4">
        <strong>بيانات الدفع.</strong> قد نقوم بجمع البيانات اللازمة لمعالجة
        دفعتك إذا قمت بإجراء عمليات شراء، مثل رقم وسيلة الدفع الخاصة بك، ورمز
        الأمان المرتبط بأداة الدفع الخاصة بك. جميع بيانات الدفع يتم تخزينها
        بواسطة Paddle. يمكنك العثور على إشعار الخصوصية الخاص بهم{" "}
        <a
          href="https://www.paddle.com/legal/privacy"
          className="text-custom-dark-blue font-bold hover:text-blue-800"
        >
          هنا
        </a>
        .
      </p>
      <p className="mb-4">
        <strong>بيانات تسجيل الدخول لوسائل التواصل الاجتماعي.</strong> قد نوفر
        لك خيار التسجيل معنا باستخدام تفاصيل حساب الوسائط الاجتماعية الحالي
        الخاص بك، مثل حساب Facebook أو Twitter. إذا اخترت التسجيل بهذه الطريقة،
        فسنجمع المعلومات الموضحة في القسم المسمى "كيف نتعامل مع تسجيلات الدخول
        الاجتماعية الخاصة بك؟" أدناه.
      </p>
      <p className="mb-4">
        يجب أن تكون جميع المعلومات الشخصية التي تقدمها لنا صحيحة وكاملة ودقيقة،
        ويجب عليك إخطارنا بأي تغييرات تطرأ على هذه المعلومات الشخصية.
      </p>

      {/* Third Section */}
      <h2 className="text-2xl font-semibold mb-2">2. كيف نعالج معلوماتك؟</h2>
      <p className="mb-4">
        باختصار: نقوم بمعالجة معلوماتك لتوفير خدماتنا وتحسينها وإدارتها،
        والتواصل معك، لأغراض الأمان ومنع الاحتيال، والامتثال للقانون. يجوز لنا
        أيضًا معالجة معلوماتك لأغراض أخرى بموافقتك.
      </p>
      <p className="mb-4">
        نقوم بمعالجة معلوماتك الشخصية لعدة أسباب، اعتمادًا على كيفية تفاعلك مع
        خدماتنا، بما في ذلك:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          لتلبية وإدارة طلباتك. قد نقوم بمعالجة معلوماتك لتلبية وإدارة طلباتك
          ومدفوعاتك وعائداتك وعمليات التبادل التي تتم من خلال الخدمات.
        </li>
        <li>
          لطلب ردود الفعل. قد نقوم بمعالجة معلوماتك عند الضرورة لطلب التعليقات
          وللاتصال بك بشأن استخدامك لخدماتنا.
        </li>
        <li>
          لتقديم الإعلانات المستهدفة لك. قد نقوم بمعالجة معلوماتك لتطوير وعرض
          محتوى وإعلانات مخصصة تناسب اهتماماتك وموقعك والمزيد.
        </li>
        <li>
          لنشر الشهادات. ننشر شهادات على خدماتنا التي قد تحتوي على معلومات
          شخصية.
        </li>
      </ul>

      {/* Fourth Section */}
      <h2 className="text-2xl font-semibold mb-2">
        3. متى ومع من نشارك معلوماتك الشخصية؟
      </h2>
      <p className="mb-4">
        باختصار: قد نشارك المعلومات في مواقف محددة موضحة في هذا القسم و/أو مع
        الأطراف الثالثة التالية.
      </p>
      <p className="mb-4">
        قد نحتاج إلى مشاركة معلوماتك الشخصية في الحالات التالية:
        <ul className="list-disc list-inside mb-4">
          <li>
            تحويلات الأعمال. يجوز لنا مشاركة معلوماتك أو نقلها فيما يتعلق أو
            أثناء المفاوضات بشأن أي اندماج أو بيع أصول الشركة، تمويل، أو
            الاستحواذ على كل أو جزء من أعمالنا لشركة أخرى.
          </li>
        </ul>
      </p>

      {/* Fifth Section  */}
      <h2 className="text-2xl font-semibold mb-2">
        4. هل نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى؟
      </h2>
      <p className="mb-4">
        باختصار: قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى لجمع
        معلوماتك وتخزينها.
      </p>
      <p className="mb-4">
        قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع المشابهة (مثل إشارات الويب
        والبكسلات) للوصول إلى المعلومات أو تخزينها. توجد معلومات محددة حول كيفية
        استخدامنا لهذه التقنيات وكيف يمكنك رفض بعض ملفات تعريف الارتباط في إشعار
        ملفات تعريف الارتباط الخاص بنا.
      </p>
      {/* Sixth Section  */}
<h2 className="text-2xl font-semibold mb-2">
  5. كيف نتعامل مع تسجيلات الدخول الخاصة بك على مواقع التواصل الاجتماعي؟
</h2>
<p className="mb-4">
  باختصار: إذا اخترت التسجيل أو تسجيل الدخول إلى خدماتنا باستخدام حساب وسائل التواصل الاجتماعي، فقد نتمكن من الوصول إلى معلومات معينة عنك.
</p>
<p className="mb-4">
  توفر لك خدماتنا القدرة على التسجيل وتسجيل الدخول باستخدام تفاصيل حساب الوسائط الاجتماعية الخاص بطرف ثالث (مثل تسجيلات الدخول الخاصة بك على Facebook
  أو Twitter). عندما تختار القيام بذلك، سنتلقى معلومات معينة عنك من مزود الوسائط الاجتماعية الخاص بك. قد تختلف معلومات الملف الشخصي التي نتلقاها
  اعتمادًا على مزود الوسائط الاجتماعية المعني، ولكنها غالبًا ما تتضمن اسمك وعنوان بريدك الإلكتروني وقائمة الأصدقاء وصورة الملف الشخصي، بالإضافة إلى
  المعلومات الأخرى التي تختار نشرها للعامة على منصة التواصل الاجتماعي هذه.
</p>
<p className="mb-4">
  سنستخدم المعلومات التي نتلقاها فقط للأغراض الموضحة في إشعار الخصوصية هذا أو التي تم توضيحها لك بطريقة أخرى في الخدمات ذات الصلة.
  يرجى ملاحظة أننا لا نتحكم، ولسنا مسؤولين عن، الاستخدامات الأخرى لمعلوماتك الشخصية من قبل مزود الوسائط الاجتماعية التابع لجهة خارجية. نوصيك
  بمراجعة إشعار الخصوصية الخاص بهم لفهم كيفية جمع معلوماتك الشخصية واستخدامها ومشاركتها، وكيف يمكنك تعيين تفضيلات الخصوصية
  الخاصة بك على مواقعهم وتطبيقاتهم.
</p>

{/* Seventh Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  6. إلى متى نحتفظ بمعلوماتك؟
</h2>
<p className="mb-4">
  باختصار: نحتفظ بمعلوماتك طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في إشعار الخصوصية هذا ما لم ينص القانون على خلاف ذلك.
</p>
<p className="mb-4">
  سنحتفظ بمعلوماتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها في إشعار الخصوصية هذا، ما لم تكن هناك فترة احتفاظ أطول مطلوبة أو
  مسموح بها بموجب القانون (مثل الضرائب، المحاسبة، أو المتطلبات القانونية الأخرى). عندما لا تكون لدينا حاجة تجارية مشروعة مستمرة لمعالجة معلوماتك الشخصية، فسنقوم إما بحذف هذه المعلومات أو إخفاء هويتها، أو، إذا كان هذا غير ممكن (على سبيل المثال، لأنه تم تخزين معلوماتك الشخصية في أرشيفات النسخ الاحتياطي)، فسنقوم بتخزين معلوماتك الشخصية بشكل آمن وعزلها عن أي معالجة إضافية حتى يصبح الحذف ممكنًا.
</p>

{/* Eighth Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  7. كيف نحافظ على معلوماتك آمنة؟
</h2>
<p className="mb-4">
  باختصار: نهدف إلى حماية معلوماتك الشخصية من خلال نظام من الإجراءات الأمنية التنظيمية والفنية.
</p>
<p className="mb-4">
  لقد قمنا بتنفيذ إجراءات أمنية فنية وتنظيمية مناسبة ومعقولة مصممة لحماية أمن أي معلومات شخصية نقوم بمعالجتها. ومع ذلك، على الرغم من الضمانات والجهود التي نبذلها لتأمين معلوماتك، لا يمكن ضمان أن يكون النقل الإلكتروني عبر الإنترنت أو تكنولوجيا تخزين المعلومات آمنًا بنسبة 100%، لذلك لا يمكننا أن نعد أو نضمن أن المتسللين أو مجرمي الإنترنت أو غيرهم من الأطراف الثالثة غير المصرح بها لن يتعرضوا للخطر. قادرة على التغلب على أمننا وجمع معلوماتك أو الوصول إليها أو سرقتها أو تعديلها بشكل غير صحيح. على الرغم من أننا سنبذل قصارى جهدنا لحماية معلوماتك الشخصية، إلا أن نقل المعلومات الشخصية من وإلى خدماتنا يكون على مسؤوليتك الخاصة. يجب عليك الوصول إلى الخدمات فقط في بيئة آمنة.
</p>
{/* Ninth Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  8. هل نقوم بجمع معلومات من القُصّر؟
</h2>
<p className="mb-4">
  باختصار: نحن لا نجمع البيانات من القاصرين أو نقوم بالتسويق لهم عن قصد.
</p>

{/* Tenth Section*/}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  9. ما هي حقوق الخصوصية الخاصة بك؟
</h2>
<p className="mb-4">
  باختصار: يمكنك مراجعة حسابك أو تغييره أو إنهائه في أي وقت.
</p>
<p className="mb-4">
  سحب موافقتك: إذا كنا نعتمد على موافقتك لمعالجة معلوماتك الشخصية، والتي قد تكون موافقة صريحة و/أو ضمنية
  وفقًا للقانون المعمول به، فيحق لك سحب موافقتك في أي وقت. يمكنك سحب موافقتك في أي وقت عن طريق الاتصال بنا
  باستخدام تفاصيل الاتصال الواردة في قسم "كيف يمكنك الاتصال بنا بشأن هذا الإشعار؟" أدناه.
</p>
<p className="mb-4">
  ومع ذلك، يرجى ملاحظة أن هذا لن يؤثر على قانونية المعالجة قبل سحبها، ولن يؤثر، عندما يسمح القانون المعمول به، على
  معالجة معلوماتك الشخصية التي تتم بالاعتماد على أسباب المعالجة القانونية بخلاف الموافقة.
</p>
<p className="mb-4">
  ملفات تعريف الارتباط والتقنيات المشابهة: تم إعداد معظم متصفحات الويب لقبول ملفات تعريف الارتباط بشكل افتراضي. إذا كنت تفضل ذلك، يمكنك عادةً اختيار ضبط المتصفح الخاص بك
  لإزالة ملفات تعريف الارتباط ورفض ملفات تعريف الارتباط. إذا اخترت إزالة ملفات تعريف الارتباط أو رفض ملفات تعريف الارتباط، فقد يؤثر ذلك على ميزات أو خدمات معينة في خدماتنا.
</p>
<p className="mb-4">
  إذا كانت لديك أسئلة أو تعليقات حول حقوق الخصوصية الخاصة بك، فيمكنك مراسلتنا عبر البريد الإلكتروني على info@kalimatkeywords.com.
</p>

{/* Eleventh Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  10. ضوابط لميزات عدم التتبع
</h2>
<p className="mb-4">
  تتضمن معظم متصفحات الويب وبعض أنظمة تشغيل الأجهزة المحمولة وتطبيقات الأجهزة المحمولة ميزة أو إعداد عدم التتبع ("DNT") الذي يمكنك
  تنشيطه للإشارة إلى تفضيلات الخصوصية الخاصة بك حتى لا تتم مراقبة وجمع بيانات حول أنشطة التصفح عبر الإنترنت. في هذه المرحلة، لم يتم
  الانتهاء من وضع معيار تكنولوجي موحد للتعرف على إشارات DNT وتنفيذها. وعلى هذا النحو، فإننا لا نستجيب حاليًا لإشارات متصفح DNT أو
  أي آلية أخرى تنقل تلقائيًا اختيارك بعدم تتبعك عبر الإنترنت. إذا تم اعتماد معيار للتتبع عبر الإنترنت والذي يجب علينا اتباعه في المستقبل،
  فسنبلغك بهذه الممارسة في نسخة منقحة من إشعار الخصوصية هذا.
</p>

{/* Twelfth Section  */}
<h2 className="text-2xl font-semibold mb-2">
  11. هل نقوم بإجراء تحديثات على هذا الإشعار؟
</h2>
<p className="mb-4">
  باختصار: نعم، سنقوم بتحديث هذا الإشعار حسب الضرورة للبقاء متوافقًا مع القوانين ذات الصلة.
</p>
<p className="mb-4">
  قد نقوم بتحديث إشعار الخصوصية هذا من وقت لآخر. ستتم الإشارة إلى الإصدار المحدث بتاريخ "منقح" محدث وسيكون الإصدار المحدث ساري
  المفعول بمجرد إمكانية الوصول إليه. إذا أجرينا تغييرات جوهرية على إشعار الخصوصية هذا، فقد نخطرك إما عن طريق نشر إشعار بهذه التغييرات
  بشكل بارز أو عن طريق إرسال إشعار إليك مباشرة. نحن نشجعك على مراجعة إشعار الخصوصية هذا بشكل متكرر لتكون على علم بكيفية حماية
  معلوماتك.
</p>

{/* Thirteenth Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  12. كيف يمكنك الاتصال بنا بشأن هذا الإشعار؟
</h2>
<p className="mb-4">
  إذا كانت لديك أسئلة أو تعليقات حول هذا الإشعار، فيمكنك مراسلتنا عبر البريد الإلكتروني على info@kalimatkeywords.com.
</p>

{/* Fourteenth Section  */}
<h2 className="text-2xl font-semibold mt-6 mb-2">
  13. كيف يمكنك مراجعة، تحديث، أو حذف البيانات التي نجمعها منك؟
</h2>
<p className="mb-4">
  استنادًا إلى القوانين المعمول بها في بلدك، قد يكون لديك الحق في طلب الوصول إلى المعلومات الشخصية التي نجمعها منك، تغيير تلك المعلومات، أو حذفها. لطلب مراجعة، تحديث، أو حذف معلوماتك الشخصية، يرجى ملء وتقديم طلب الوصول إلى بيانات الشخص المعني.
</p>


    </div>
  );
}

export default PolicyPageContentARB;
